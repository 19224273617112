<template>
  <dryness-template
    :considerable-dryness="considerableDryness"
    :skin-dryness="skinDryness"
    :flaking-skin="flakingSkin"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DrynessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dryness/DrynessTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';
import { AMOUNT } from '@/modules/questionnaire/api/constants';

const { requiredField, field } = fieldBuilder;

const fieldsToReset = [
  'facialDrynessLocations',
  'drynessCausedByClimate',
  'drynessOccurrence',
  'drynessDiscomfort'
];

const FIELDS = [
  ...fieldsToReset.map(field),
  requiredField('skinDryness'),
  requiredField('flakingSkin')
];

export default {
  name: 'Dryness',
  components: {
    DrynessTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    considerableDryness() {
      return this.skinDryness === 'considerably';
    }
  },
  watch: {
    skinDryness(newValue) {
      this.resetStoreFieldsByName(['flakingSkin']);

      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      if (newValue !== AMOUNT.CONSIDERABLY) {
        this.showNextStep();
      } else {
        this.scrollTo('#flaking-skin');
      }
    },
    flakingSkin(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'flakingSkin') {
        return this.considerableDryness;
      }

      return true;
    }
  }
};
</script>
